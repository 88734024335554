import "./App.scss";
import Logo from "./Assets/Images/DS-Logo-trans-bg.png";

function App() {
  return (
    <div className="App">
      <div className="App-Contact">
        <h2>Tel: 087 004 0200</h2>
        <h2>Email: info@digital-support.co.za</h2>
      </div>
      <div className="App-logo">
        <img src={Logo} alt="nologo" />
      </div>
    </div>
  );
}

export default App;
